.description {
    position: absolute;
    left: 50px;
    top: 50px;
    opacity: 0;
    transform: translate(0, -100%);
    transition: transform 1.5s ease-in-out, opacity 2s ease-in-out;
}

.description-show {
    transform: translate(0);
    opacity: 1;
}

h1 {
    color: #d6d333;
    font-family: 'Roboto Mono', monospace;
    font-size: 60px;
}

#intro {
    color: #d6d333;
    font-family: 'Roboto Mono', monospace;
    font-size: 30px;
}

#location {
    color: #d6d333;
    font-family: 'Roboto Mono', monospace;
    font-size: 25px;
    margin: auto;
    margin-left: 10px;
}

.location {
    margin-left: 240px;
    margin-top: -30px;
    margin-bottom: 70px;
    display: flex;
    align-content: center;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    h1 {
        font-size: 8.5vw;
        margin: auto;
    }

    #intro {
        font-size: 6vw;
        margin-left: 20px;
    }

    #location {
        font-size: 5vw;
    }

    .description {
        left: 10px;
        top: 10px;
    }

    .location {
        margin: auto;
        margin-bottom: 70px;
    }
    .location img {
        height: 8vw;
        width: 8vw;
    }
}
