.sidebar {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(10%, -50%);
    opacity: 0;
    transition: transform 0.5s ease-in-out, opacity 1s ease-in-out;
}

.sidebar-show {
    opacity: 1;
    transform: translate(0, -50%);
}

a:link {
    color: #b6b42d;
}

a:visited {
    color: #b6b311;
}

a:hover {
    color: #d6d333;
}

a:active {
    color: #b6b42d;
}

.side-btn {
    color: #d6d333;
    font-family: 'Roboto Mono', monospace;
    font-size: 50px;
    margin: auto;
    margin-left: 0;
    padding-left: 20px;
    padding-right: 25px;
}

.sidebar-btn:hover {
    transform: translate(0, 0);
}

.sidebar-btn-github:hover {
    transform: translate(6%, 0) !important;
}

.sidebar-btn-about:hover {
    transform: translate(45%, 0) !important;
}

.sidebar-btn {
    padding: 10px;
    display: flex;
    align-content: center;
    justify-content: center;
    transform: translate(85%, 0);
    transition: transform 0.4s;
}

.sidebar-btn img {
    filter: drop-shadow(0 0 0.75rem black);
}

@media screen and (max-width: 600px) {
    .sidebar-btn img {
        height: 15vw;
        width: 15vw;
    }

    .sidebar-btn {
        transform: translate(80%, 0);
    }

    .side-btn {
        font-size: 7vw;
        margin: auto;
        margin-left: 0;
        padding-left: 10px;
        padding-right: 25px;
    }
}
